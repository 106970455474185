.demo_button {
    width: Hug (195px)px;
height: Hug (48px)px;
padding: 6px 6px 6px 20px;
gap: 12px;
border-radius: 58px 0px 0px 0px;
border: 2px 0px 0px 0px;
opacity: 0px;

}
.inner_button {
    width: 36px;
height: 36px;
top: 6px;
left: 153px;
gap: 0px;
border-radius: 40px 0px 0px 0px;
opacity: 0px;
background: #2C155D;

}